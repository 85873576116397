// large feature (photo/text/links)

.landing-page {
  .large-feature {
    padding: 4rem;
    background: url("#{$path-images}/slashes-grey-bg.gif") repeat;
  }

  .large-feature-inner {
    max-width: 1120px;
    margin: 3rem auto 0 auto;
    box-sizing: content-box;
    @extend %clearfix;

    .content-side {
      width: 880px / 1120px * 100%;
      float: left;
      border-right: 1px solid $medium-grey;
      padding-right: 40px / 1120px * 100%;

      img {
        float: left;
        margin: 0 2rem 2rem 0;
      }

      h4 {
        font: 48px/1.1em $CamptonBold;
        margin-bottom: 3rem;
        color: $nu-purple;
      }

      .text ul {
        overflow: hidden;
      }
    }

    .right-side {
      // new setup for links/other content on right side
      width: 240px / 1120px * 100%;
      float: left;
      padding-left: 1.5rem;

      h4 {
        font: 24px $AkkuratProRegular;
        padding-bottom: 0.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 4px solid $medium-grey;
        color: $rich-black-50;
      }

      ul {
        padding-left: 0;
        margin-left: 0;

        li {
          background: none;
          line-height: 1.2rem;
          margin: 0 0 1rem 0;
          padding-left: 0;
          @extend %purple-right-arrow;

          a {
            font-family: $AkkuratProBold;
          }
        }
      }
    }
  }

  // media queries
  @media screen and (max-width: 1100px) {
    .large-feature-inner {
      .content-side,
      > ul,
      .right-side {
        float: none;
        width: 100%;
        border: none;
        border-right: 0;

        li {
          padding-left: 0;
        }
      }

      .content-side {
        padding-right: 0;

        &:after {
          content: "";
          display: table;
          clear: both;
        }
      }

      .right-side {
        padding-left: 0;
      }
    }
  }

  @media screen and (max-width: 850px) {
    .large-feature-wrap {
      padding: 2rem;
    }

    .large-feature {
      padding: 2rem;

      .content-side {
        img {
          float: none;
          margin-bottom: 1rem;
        }

        h4 {
          font-size: 36px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .large-feature-wrap {
      padding: 1rem;
    }

    .large-feature {
      padding: 2rem 1rem;

      h3 {
        font-size: 20px;

        &:before,
        &:after {
          background: none;
        }
      }

      .content-side {
        h4 {
          font-size: 28px;
        }
      }
    }
  }
} // end
