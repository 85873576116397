// link boxes - grey header with grey line below, optional photo and links

.standard-page {
  // i.e. link boxes for diversity page at bottom
  .two-column-links {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; // equal height columns
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; // wrap child items
    margin: 2rem 0 0 0;

    article {
      width: 400px / 836px * 100%;

      &:nth-child(even) {
        margin-left: 36px / 836px * 100%;
      }

      &:nth-child(n + 3) {
        // adds margin when more than 2
        margin-top: 2rem;
      }

      img {
        margin-bottom: 0.5rem;
      }

      h3,
      h4 {
        font: 26px $AkkuratProRegular;
        color: $rich-black-50;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        border-bottom: 4px solid $medium-grey;
      }

      ul {
        margin: 0;
        padding: 0;
      }
    }
  }

  // media queries
  @media screen and (max-width: 600px) {
    .two-column-links {
      article {
        float: none;
        width: 100%;
        margin-bottom: 2rem;

        &:nth-child(even) {
          margin-left: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:nth-child(n + 2) {
          margin-top: 0;
        }
      }
    }
  }
} // end
