// three columns of links with images

.landing-page {
  .three-column-links {
    @extend %clearfix;

    h4 {
      font: 30px $AkkuratProRegular;
      color: $rich-black-50;
      border-bottom: 4px solid $medium-grey;
      text-transform: none;
      padding-bottom: 0.5rem;
    }

    article {
      float: left;
      width: 360px / 1120px * 100%;
      margin-right: 20px / 1120px * 100%;

      &:last-child {
        margin-right: 0;
      }

      &:nth-child(n + 4) {
        // adds margin when more than 3
        margin-top: 2rem;
      }

      img {
        margin-bottom: 1rem;
      }

      p {
        margin-top: 0;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  // media queries
  @media screen and (max-width: 875px) {
    .three-column-links {
      article {
        float: none;
        width: inherit;
        max-width: 360px;
        margin: 0 auto 2rem auto;

        &:last-child {
          margin-right: auto; // override
          margin-bottom: 0;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .three-column-links {
      h4 {
        font-size: 24px;
      }

      article {
        &:nth-child(n + 3) {
          margin-top: 0;
        }
      }
    }
  }
} // end
