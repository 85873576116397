// standard page (with or without left navigation)

.standard-page {
  .section {
    margin-bottom: 3rem;

    &:first-of-type {
      // gives extra space to first module
      margin-top: 3rem;
    }

    &.hero:first-of-type {
      // removes from hero
      margin-top: 0;
    }
  }

  #page {
    margin: 2rem auto 0 auto;
    max-width: 1120px;
    @extend %clearfix;
  }

  .hero + #page {
    margin-top: -1em;
  }

  // main content regions
  #main-content {
    // gives content space before hitting footer
    min-height: 300px;
    margin-bottom: 5rem;
  }

  .content {
    width: 836px / 1120px * 100%;
    float: right;
    margin-bottom: 3rem;
  }

  ul + h4,
  ul + h5,
  ul + h6,
  ol + h4,
  ol + h5,
  ol + h6 {
    margin-top: 2rem;
  }

  p + h3,
  p + h4,
  p + h5,
  p + h6,
  ul + h3,
  ul + h4,
  ul + h5,
  ul + h6,
  ol + h3,
  ol + h4,
  ol + h5,
  ol + h6 {
    margin-top: 3rem;
  }

  // right sidebar option
  #sidebar {
    width: 260px / 836px * 100%;
    float: right;
    margin: 0 0 2rem 2rem;

    .box {
      background: $nu-purple-10;
      padding: 1rem;
      margin-bottom: 2rem;
      font-size: 14px;
      line-height: 1.4em;

      h3 {
        font: 18px/1.2em $CamptonBold;
        color: $rich-black-80;
      }

      h4 {
        font: 16px/1.2em $AkkuratProBold;
        color: $rich-black-80;
        margin-top: inherit;
      }

      ul {
        padding-left: 0.5rem;

        li {
          background-position: 0 0.3em;
        }
      }
    }
  }

  // pagination
  .pagination {
    margin: 2.5em 0 2em;
    padding: 1rem 0 0 0;
    border-top: 1px solid $medium-grey;
    list-style-type: none;

    li {
      display: inline-block;
      padding: 0;
      background-image: none;
      font: 16px $AkkuratProBold;
      color: $rich-black-20;

      &.disabled {
        padding: 0.8rem 1rem;
      }
    }

    a {
      border: none;
      display: inline-block;
      padding: 0.8rem 1rem;
      text-decoration: none;
      color: $rich-black-80;

      &:hover {
        background: $nu-purple-30;
        color: $white;
      }

      &.current {
        background: $nu-purple;
        color: $white;
      }
    }
  }

  // ordered list for steps (big numbers)
  ol {
    &.steps {
      counter-reset: li;
      list-style: none;
      padding: 0;
      margin-bottom: 1em;

      li {
        position: relative;
        display: block;
        padding: 0;
        margin-bottom: 1em;
        padding: 1em 0.5em 0 5em;
        min-height: 90px;

        &:before {
          content: counter(li);
          counter-increment: li;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          font: 72px $CamptonBold;
          color: $nu-purple;
          margin: 0;
          padding: 0 0.15em 0 0.15em;
          text-align: center;
        }
      }
    }
  }

  // media queries
  @media screen and (max-width: 1145px) {
    #page {
      // gives left/right margin for smaller screen widths
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: 970px) {
    #page-narrow {
      // gives left/right margin for smaller screen widths
      margin-left: 1rem;
      margin-right: 1rem;
    }

    #sidebar {
      width: 300px / 768px * 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      float: none;
      width: 100%;

      h2 {
        font-size: 36px;
      }
    }

    #sidebar {
      width: 300px / 768px * 100%;
    }
  }

  @media screen and (max-width: 700px) {
    #sidebar {
      margin-left: 0;
      width: 100%;
      float: none;

      .box {
        margin-bottom: 1rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      h2 {
        font-size: 32px;
      }

      h3 {
        font-size: 24px;
      }
    }
  }
} // end
