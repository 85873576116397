.rs-facet-title,
.rs-facet-title > h2 {
  border-bottom: none !important;
  color: $rich-black-50 !important;
  margin: 0.75rem 0 0 !important;
  font-size: 14px !important;
}

.rs-facet-list {
  border: none !important;
  padding-right: 1rem !important;

  > li {
    padding-bottom: 0;
  }
}

.rs-facet-checkbox + label:hover {
  color: $nu-purple !important;
}
.rs-facet-checkbox:hover + label::before {
  border-color: $nu-purple !important;
  color: $nu-purple !important;
}
.rs-facet-checkbox:checked + label::before {
  border-color: $nu-purple !important;
  background-color: $nu-purple !important;
}

.facets-sidebar {
  @extend #left-nav;

  display: inline-block;
  float: none;
  position: relative;
  transition: opacity 0.25s ease-in-out;
  opacity: 1;

  div[class*="-Container"] > input {
    @include substitute-sans;
  }

  ul {
    border: 1px solid $rich-black-10;
  }

  li {
    @include substitute-sans;
    font-size: 14px;
    line-height: 1em;
  }

  // Collapsed state
  &.collapsed {
    width: 0;
    opacity: 0;
    display: none;
  }

  .facet-sidebar-collapse-icon {
    color: $nu-purple;
    cursor: pointer;
  }

  .facet-sidebar-content-wrapper {
    visibility: visible;

    &.hidden {
      visibility: hidden;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

a.facet-sidebar-collapse-link {
  color: $nu-purple !important;
  background: $light-grey;
  padding: 3px 6px;
}

// This applies an extended style to a collapsed sidebar
.standard-page .content {
  &.extended {
    width: 100%;
    float: none;
  }
}

.rs-facet-icon {
  // Checkbox color
  &:after {
    border-color: $nu-purple !important;
  }
}

svg.search-icon {
  fill: $nu-purple !important;
  margin-bottom: 3px;
}

.rs-result-item {
  box-shadow: none !important;
}

.rs-result-item article {
  max-height: 80px;
}

.rs-pagination {
  > .active {
    background-color: $nu-purple;
    color: $white;
    &:hover,
    &:focus {
      background-color: $nu-purple-60;
      border-color: $nu-purple-60;
    }
  }
}

.rs-selected-filters > a {
  @include substitute-sans;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .web-form input.rs-search-input {
    width: 100%;
  }
}
