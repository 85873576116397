// photo grid

@mixin photo-grid-article-label {
  .photo-grid {
    @include article-label;
  }
}

@mixin swiper-article-label {
  .swiper-wrapper {
    @include article-label;
  }
}

@mixin article-label {
  article {
    h4 {
      @include substitute-sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5em;

      > span {
        font-family: inherit !important;
        word-break: break-word;
      }
    }

    > p {
      @include substitute-sans;
    }
  }
}
