.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 33%;
  background: linear-gradient(
    to bottom,
    rgba(22, 21, 20, 0) 0%,
    rgba(22, 21, 20, 0.8) 50%,
    #161514 100%
  );
}

.homepage-hero-wrapper {
  margin-bottom: 4rem;
}

.homepage-content {
  background-color: rgba(22, 21, 20, 0.7);
  font-size: 1rem;
  color: $white;
  margin: 20px;
  padding: 20px;
}
