// photo grid

.landing-page {
  .photo-grid {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; // equal height columns
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; // wrap child items
    margin-top: 1.5rem;
    @extend %clearfix;

    a img {
      //
      opacity: 1;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 0.5;
      }
    }

    article {
      width: 265px / 1120px * 100%;
      margin-right: 20px / 1120px * 100%;
      margin-bottom: 20px;
      float: left;
      line-height: 1.5rem;

      &:nth-of-type(4n + 4) {
        // every 4th item
        margin-right: 0;
      }

      h4 {
        font: 16px $AkkuratProBold;
        color: $rich-black-80;
        margin: 1rem 0 0 0;
      }

      h4 + p {
        margin-top: 0.5rem;
      }
    }
  }

  // media queries
  @media screen and (max-width: 768px) {
    .photo-grid article {
      width: 372.48px / 768px * 100%;
      max-width: 265px;
      margin-right: 23.04px / 768px * 100%;

      &:nth-of-type(2n + 2) {
        // every 2nd item
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 475px) {
    .photo-grid article {
      width: 100%;
    }
  }
} // end
