// reset

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, img, ins, kbd, q, s, samp, small, strike, tt, var, u, center, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, button, 

//custom additions
#top-nav ul, 
#top-nav li, 
#global-links ul, 
#global-links li, 
#mobile-nav ul, 
#mobile-nav li, 
#left-nav ul, 
#left-nav li, 
ul#breadcrumbs, 
ul#breadcrumbs li, 
footer ul, 
footer li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

// HTML5 reset for older browsers that nobody uses anymore
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
main,
section {
  display: block;
}

body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
