// expand/collapse (i.e. for faq's)

// standard FAQ style
.expander1 {
  border-top: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;
  margin: 2rem 0;

  h3,
  h4 {
    > a {
      background: url("#{$path-images}/icons/arrow-right-dkpurple.svg")
        no-repeat 0.5rem center / 8px 16px;
      padding: 0.5em 0 0.5rem 2rem;
      border: none; // removes default border

      &:hover {
        border: none;
        text-decoration: underline;
      }
    }

    &.open a {
      background: url("#{$path-images}/icons/arrow-down-dkpurple.svg") no-repeat
        0.3rem center / 16px 8px;
    }
  }
}

// standard FAQ style with contained dropdown and optional data field on right side
.expander2 {
  border: 1px solid $light-grey;
  margin: 2rem 0;

  h3,
  h4 {
    > a {
      background: url("#{$path-images}/icons/arrow-right-dkpurple.svg")
        no-repeat 0.5rem center / 8px 16px;
      padding: 0.5em 0 0.5rem 2rem;
      border: none; // removes default border

      &:hover {
        border: none;
        text-decoration: underline;
      }
    }

    &.open a {
      background: url("#{$path-images}/icons/arrow-down-dkpurple.svg") no-repeat
        0.3rem center / 16px 8px;
    }

    span {
      float: right;
      border-right: 1px solid $light-grey;
      padding-right: 0.5rem;
    }
  }
}

// purple background with arrow on right side
.expander3 {
  margin: 2rem 0;

  h3,
  h4 {
    background: $nu-purple;

    > a {
      background: url("#{$path-images}/icons/arrow-right-white.svg") no-repeat
        right 1rem center / 12px 18px;
      padding: 0.5em 3rem 0.5rem 1rem;
      color: $white;
      border: none; // removes default border

      &:hover {
        border: none;
        text-decoration: underline;
      }
    }

    &.open a {
      background: url("#{$path-images}/icons/arrow-down-white.svg") no-repeat
        right 1rem center / 18px 12px;
    }
  }
}

// basics
.expander {
  h3,
  h4 {
    margin: 0;
    font-size: 18px;
    line-height: inherit;
    border-top: 1px solid $light-grey;

    &:first-of-type {
      border-top: none;
    }

    > a {
      display: block;
      margin: 0;
    }
  }

  > div {
    margin: 1rem 2rem;

    h4 {
      color: $rich-black-50;
      font: 26px/1.2em "Akkurat Pro Regular", Arial, sans-serif;
      margin-bottom: 1rem;
      background: none;
    }
  }
}

@media screen and (max-width: 480px) {
  // overrides h3 font-size change to 24px from /scss/standard-page/_main.scss
  .content .expander h3,
  .content .expander1 h3,
  .content .expander2 h3,
  .content .expander3 h3 {
    font-size: 18px;
  }
}
