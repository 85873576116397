// Collapsible arrow styles
.expander h3:first-of-type {
  border-top: 1px solid $light-grey;
}

.expander1 h3 > a,
.expander1 h4 > a {
  @include collapsible-down-arrow();
}

.expander1 h3.open a,
.expander1 h4.open a {
  @include collapsible-right-arrow();
}

// Override form inputs only being 50% wide
.web-form {
  input.rs-search-input {
    @include substitute-sans;
    padding-left: 30px;
  }
}

#mobile-search {
  display: block;
}

// Make tabs display a little nicer on mobile
#tabs {
  display: flex;
  justify-content: center;
}

.landing-page {
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    z-index: 10;
  }
  img {
    margin: 0 auto;
  }

  .swiper-button-disabled {
    opacity: 0.5;
  }

  // For works
  .photobox-swiper {
    .swiper-button-next,
    .swiper-button-prev {
      top: 40%;
    }
  }
  .photo-box {
    h4 {
      font: 16px $AkkuratProBold;
      color: $rich-black-80;
      margin: 1rem 0 0 0;
    }
    img {
      margin: 0 auto;
    }
  }
  // For overlay contrast
  .text-over-image {
    h4 {
      background-color: rgba($color: #000000, $alpha: 0.5);
      padding: 0.5rem;
      max-width: 350px;
      margin: 0 auto; //center content in the container
    }
  }
}

@media screen and (max-width: 768px) {
  .mobile-link {
    // Because we're using a <button> instead of <a>, for accessibility
    padding: 0;

    &.mobile-nav-link {
      background: url("../../../../images/hamburger-purple.svg") no-repeat
        center/20px 20px;
    }
    &.mobile-search-link {
      background: url("../../../../images/search-purple.svg") no-repeat
        center/20px 20px;

      &.open {
        background: #401f68 url("../../../../images/alert-x-white-home.svg")
          no-repeat center/20px 20px;
      }
    }
  }

  #mobile-nav-bottom {
    #mobile-nav-bottom-left {
      width: 100%;
      display: block;
    }
  }
}

// Makes photobox items full-width on mobile display
@media screen and (max-width: 475px) {
  %mobile-article {
    max-width: 100%;
    margin-right: 0;
  }
  .landing-page,
  .standard-page {
    .photo-grid {
      &.three-grid,
      &.four-grid {
        article {
          @extend %mobile-article;
        }
      }

      article {
        @extend %mobile-article;
      }
    }
  }
}
