// stats callout

.landing-page {
  .stats-callout {
    border-top: 4px solid $light-grey;
    border-bottom: 4px solid $light-grey;
    text-align: center;
    padding: 2rem 0;
    display: flex; // equal height
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap; // break into multiple lines
    @extend %clearfix;

    div {
      width: 33%;
      float: left;
      padding: 1rem;
      margin: 0 auto;
    }

    span {
      display: block;

      &.big {
        font: 72px/1.1em $CamptonBold;
        color: $nu-purple;
      }

      &.small {
        font: 24px $CamptonExtraLight;
        color: $nu-purple;
      }
    }

    // for single callout
    &.one-callout {
      // append class for full-width single callout item
      display: block;

      div {
        width: 100%;
        float: none;
      }

      span {
        display: block;
      }
    }
  }

  // media queries
  @media screen and (max-width: 800px) {
    .stats-callout {
      span {
        &.big {
          font: 48px $CamptonBold;
        }

        &.small {
          font: 20px $CamptonExtraLight;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    .stats-callout {
      span {
        &.big {
          font: 24px $CamptonBold;
        }

        &.small {
          font: 15px $CamptonExtraLight;
        }
      }
    }
  }
} // end
