// hero images

.hero {
  width: 100%;
  display: table;

  &.mobile-hero {
    // add class for mobile hero text treatment

    .mobile-hero-text {
      display: none;
    }
  }

  .hero-image {
    text-align: center;
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    position: relative;
    padding: 0 1rem;

    &:before {
      content: " ";
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: -moz-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.45) 100%
      );
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(0, 0, 0, 0)),
        color-stop(100%, rgba(0, 0, 0, 0.45))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.45) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.45) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.45) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.45) 100%
      );
    }
  }

  h2 {
    font: 24px $CamptonExtraBold;
    color: $white;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 1);
    margin-bottom: 1.5em;
    position: relative;

    &:before {
      background: url("#{$path-images}/icons/ltpurple-slash.svg") no-repeat 50% /
        17px 21px;
      padding: 0 20px;
      content: " ";
    }

    &:after {
      background: url("#{$path-images}/icons/ltpurple-slash.svg") no-repeat 50% /
        17px 21px;
      padding: 0 20px;
      content: " ";
    }
  }

  p {
    font: 60px/1.2em $CamptonBold;
    color: $white;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 1);
    margin: 0 0 1rem 0;
    position: relative;

    &.description {
      font: 24px/1.2em $AkkuratProRegular;
    }
  }
}

// landing page in-page hero
.landing-page {
  .hero {
    .hero-image {
      // for top of page
      height: 600px;

      &.in-page {
        // slighter shorter for within the page
        height: 540px;

        h3 {
          font: 24px $CamptonExtraBold;
          color: $white;
          text-shadow: 2px 2px 0px rgba(0, 0, 0, 1);
          margin-bottom: 1.5em;
          position: relative;

          &:before {
            background: url("#{$path-images}/icons/ltpurple-slash.svg")
              no-repeat 50% / 17px 21px;
            padding: 0 20px;
            content: " ";
          }

          &:after {
            background: url("#{$path-images}/icons/ltpurple-slash.svg")
              no-repeat 50% / 17px 21px;
            padding: 0 20px;
            content: " ";
          }
        }
      }

      h2 {
        color: $white;

        &:before {
          background: url("#{$path-images}/icons/ltpurple-slash.svg") no-repeat
            50% / 17px 21px;
        }

        &:after {
          background: url("#{$path-images}/icons/ltpurple-slash.svg") no-repeat
            50% / 17px 21px;
        }
      }
    }

    // for static news hero image replacement
    &.news-hero {
      .hero-image {
        vertical-align: bottom !important;
        padding-bottom: 2.5rem;
      }
    }
  }

  // override for top level pages that use smaller heroes
  .small-hero {
    .hero-image {
      height: 420px;
    }
  }
}

// standard page
.standard-page {
  .hero {
    .hero-image {
      height: 420px;

      &.tiny-hero-image {
        // for pages with smaller (than small) hero images (i.e. events page on top-level)
        height: 190px;

        h2 {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .hero {
    p {
      font-size: 48px;
    }
  }
}

@media screen and (max-width: 768px) {
  .standard-page,
  .landing-page {
    .hero {
      &.mobile-hero {
        // append this class to .hero to move the overlapping hero text below the image

        .hero-image {
          display: block;
          height: 300px;

          div {
            display: none;
          }
        }

        .mobile-hero-text {
          display: block;
          text-align: center;
          background: $rich-black-80;
          padding: 2rem 1em;

          h2 {
            font-size: 24px;
            text-shadow: none;
            margin-bottom: 0;

            & + p,
            & + .button {
              margin-top: 1rem;
            }
          }

          p {
            text-shadow: none;
            font-size: 48px;
          }
        }
      }
    }

    .standard-page {
      .hero-image {
        &.big-hero-image {
          height: 350px;
        }
      }

      &.m-hide-hero {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .landing-page,
  .standard-page {
    .hero {
      .hero-image {
        height: 400px;

        &.big-hero-image {
          // removes people hero from content page
          display: none;
        }

        &.in-page {
          // slighter shorter for within the page
          height: 400px;

          h3 {
            font-size: 20px;
            margin-bottom: 1rem;

            &:before {
              background: none;
              padding: 0;
            }

            &:after {
              background: none;
              padding: 0;
            }
          }
        }
      }

      h2 {
        font-size: 20px;
        margin-bottom: 1rem;

        &:before {
          background: none;
          padding: 0;
        }

        &:after {
          background: none;
          padding: 0;
        }
      }

      p {
        font-size: 28px;

        &.description {
          font-size: 20px;
        }
      }

      &.mobile-hero {
        // append this class to .hero to move the overlapping hero text below the image

        .hero-image {
          height: 200px;
        }

        .mobile-hero-text {
          padding: 1em 0;

          h2 {
            font-size: 20px;
          }

          p {
            font-size: 28px;
          }
        }
      }
    }
  }

  // standard page
  .standard-page {
    .hero {
      &.smaller-hero {
        // for pages with short hero images (i.e. events landing page on top-level)

        .hero-image {
          height: 190px;
        }
      }
    }
  }
}
