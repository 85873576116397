// news and events (original versions with edits)

.landing-page {
  .news-event {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; // equal height columns
    @extend %clearfix;

    .section {
      margin-bottom: 2rem;
    }
  }

  .news,
  .events {
    border-bottom: 4px solid $light-grey;

    h4 {
      font: 30px/1.2em $AkkuratProRegular;
      margin-bottom: 1.5rem;
      padding-bottom: 0.5rem;
      color: $rich-black-50;
      border-bottom: 4px solid $light-grey;
    }

    h5 {
      font: 18px/1.3em $AkkuratProBold;
      margin-bottom: 0.5rem;
      padding-bottom: 0;
      border: none;
    }
  }

  .news {
    float: left;
    width: 740px / 1120px * 100%;
  }

  .news-box {
    float: left;
    width: 360px / 740px * 100%;
    margin-right: 20px / 740px * 100%;
    margin-bottom: 1rem;
    @extend %clearfix;

    &:nth-of-type(2n) {
      margin-right: 0;
    }

    > p {
      margin-top: 0;
    }
  }

  // for showcase on news & events with 2 calendars showcase
  .showcase .news {
    width: 360px / 1120px * 100%;
    padding-bottom: 1rem;

    img {
      margin-bottom: 0.5rem;
    }

    h5 {
      color: $rich-black-50;
    }
  }

  .events {
    float: left;
    width: 360px / 1120px * 100%;
    margin-left: 20px / 1120px * 100%;

    h5 {
      color: $rich-black-50;
    }

    p {
      margin-top: 0;
    }
  }

  // media queries
  @media screen and (max-width: 900px) {
    .news {
      width: 60%;

      .news-box {
        width: 100%;
        float: none;
      }
    }

    .events {
      width: 40%;
      margin-left: 20px / 900px * 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .news-event {
      &.showcase {
        display: inherit;
        margin-bottom: 3rem;

        .news,
        .events {
          width: 100%;
          float: none;
          margin-bottom: 3rem;
          margin-left: 0;
          border-bottom: none;

          &:nth-child(3) {
            margin-bottom: 0;
          }
        }
      }

      .news-box p:last-of-type {
        // removes margin for News and Events (no news photo)
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 550px) {
    .news-event {
      display: inherit;
      margin-bottom: 3rem;
    }

    .news,
    .events {
      width: 100%;
      float: none;
      margin-bottom: 3rem;
      margin-left: 0;
      border-bottom: none;
    }
  }

  @media screen and (max-width: 550px) {
    .news,
    .events {
      h5 {
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .news,
    .events {
      h4 {
        font-size: 24px;
      }
    }
  }
} // end
