// internet explorer warning

#ie {
  background: #fcf3c5;
  font: 1.5em/1em Arial, sans-serif;
  text-align: center;
  padding: 0.5em;
  border-bottom: 1px solid $black;
}

#ie a {
  font-weight: bold;
}

#ie p {
  margin: 0;
}
