// feature boxes (3 columns)

.landing-page {
  .photo-feature-3-across {
    margin-top: 3rem;
    @extend %clearfix;

    .photo-feature {
      width: 33.333333%;
      float: left;
      position: relative;
      max-height: 350px;

      // for hover state when no back text
      a:hover {
        opacity: 0.8;
      }

      img {
        display: block;
      }

      .back {
        display: none;
        background-color: $nu-purple-60;
        width: 100%;
      }

      .text-over-image {
        color: $white;
        padding: 0 2em;
        position: absolute;
        bottom: 1rem;
        z-index: 31;
        width: 90%;
      }

      h4 {
        font: 30px $AkkuratProBold;
        color: $white;
        margin-bottom: -0.5rem;
        text-shadow: 2px 2px 0px rgba(0, 0, 0, 1);
      }

      p {
        font: 16px/1.3em $AkkuratProRegular;
        text-shadow: 2px 2px 0px rgba(0, 0, 0, 1);

        &.link {
          font-family: $AkkuratProBold;
          text-transform: uppercase;
          @extend %white-right-arrow;
        }
      }

      .back-text {
        color: $white;
        padding: 0 2em;
        position: absolute;
        z-index: 31;
        width: 100%;
        top: 50%;
        left: 50%;
        // vertically center absolutely positioned text
        transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        text-align: center;

        h4 {
          font: 18px $AkkuratProBold;
          color: #fff;
          margin-bottom: 1.5em;
          text-shadow: none;
        }

        p {
          text-shadow: none;

          &.link {
            margin-top: 1em;
          }
        }
      }
    }
  }

  // media queries
  @media screen and (max-width: 1150px) {
    .photo-feature-3-across {
      .photo-feature {
        .text-over-image {
          padding: 0 1em;

          h4 {
            font-size: 26px;
          }
        }

        .back-text {
          padding: 0 1em;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    .photo-feature-3-across {
      .photo-feature {
        width: 50%;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .photo-feature-3-across {
      .photo-feature {
        width: inherit;
        max-width: 480px;
        float: none;
        margin: 0 auto 0 auto;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .photo-feature-3-across {
      .photo-feature {
        width: inherit;
        max-width: 480px;
        margin: 0 auto;
        float: none;

        .text-over-image {
          width: 100%;

          h4 {
            font-size: 24px;

            &.campton {
              font-size: 30px;
            }
          }

          p {
            display: none;

            &.link {
              display: block;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    .photo-feature-3-across .photo-feature {
      .back-text {
        padding: 0 0.5rem;

        h3 {
          font-size: 16px;
          margin-bottom: 0.5rem;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
} // end
