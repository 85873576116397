// narrow page
// no left navigation and 930px wide
// <body> must include: <body class="standard-page narrow-page">

.narrow-page {
  #page {
    margin: 2rem auto 0 auto;
    max-width: 930px;

    .content {
      // override _main.scss
      width: 100%;
      float: none;
    }
  }

  #news-story {
    h2 {
      margin-bottom: 1.5rem;
    }

    .wrap {
      margin-bottom: 1.5rem;
      @extend %clearfix;

      #credit {
        font: 16px/1.4em $CamptonBold;
        color: $rich-black-80;
        width: 645px / 930px * 100%;
      }

      #share {
        float: right;
        width: 265px / 930px * 100%;
      }
    }

    #story-floater {
      float: right;
      width: 265px / 930px * 100%;
      margin-left: 20px / 930px * 100%;
      margin-bottom: 20px / 930px * 100%;

      figure + .news-tidbit {
        margin-top: 2rem;
      }

      #story-callout {
        border-top: 4px solid $light-grey;
        border-bottom: 4px solid $light-grey;
        padding: 1.5rem 0;
        font: 20px $CamptonBook;
        color: $nu-purple;
      }
    }
  }

  // center modules
  main {
    .section {
      max-width: 836px;
    }
  }

  @media screen and (max-width: 970px) {
    #page {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    #news-story {
      #story-floater {
        float: none;
        width: 100%;
        padding-top: 1.5rem;
        margin-left: 0;
        margin-bottom: 1.5rem;
        border-top: 4px solid $light-grey;

        .quote {
          float: none;
          width: 100%;
          font-size: 18px;
          border-bottom: 4px solid $light-grey;
        }

        #story-callout {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    #news-story {
      .wrap {
        #share {
          float: none;
          width: 100%;
          margin-bottom: 1rem;
        }

        #credit {
          float: none;
          width: 100%;
        }
      }
    }
  }
} // end
