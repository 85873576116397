// font stack
$sans: Arial, Helvetica, sans-serif;
$display: "Poppins", $sans;

$brand-sans-regular: $AkkuratProRegular, $sans;
$brand-sans-bold: $AkkuratProBold, $sans;

$brand-display-extra-light: $CamptonExtraLight, $display;
$brand-display-book: $CamptonBook, $display;
$brand-display-bold: $CamptonBold, $display;
$brand-display-extra-bold: $CamptonExtraBold, $display;
