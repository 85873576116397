// mixins and extends

// mixins
// clearfix in media queries
@mixin clearfix() {
  content: "";
  display: table;
  clear: both;
}

// for form fields
@mixin borderFade($s: 0.3s) {
  -webkit-transition: $s linear border;
  -moz-transition: $s linear border;
  -ms-transition: $s linear border;
  -o-transition: $s linear border;
  transition: $s linear border;
}

// extends (don't use in media queries.  duh.)
// clearfix
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// down-arrow for top-navigation
%purple-right-arrow {
  &:after {
    background: url("#{$path-images}/icons/arrow-right-dkpurple.svg") no-repeat
      50% 45% / 6px 10px;
    padding-right: 1.5rem;
    content: " ";
  }
}

// right arrow (i.e. for alternate photo float)
%white-right-arrow {
  &:after {
    background: url("#{$path-images}/icons/arrow-right-white.svg") no-repeat 50%
      45% / 6px 10px;
    padding-right: 1.5rem;
    content: " ";
  }
}

// down arrow for resources
%white-down-arrow {
  &:after {
    content: "\003E";
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    margin-left: 0.4rem;
    color: $white;
    display: inline-block;
  }
}
