#library-search-button {
  background: url("../../../../images/library-search.svg") no-repeat center/18px
    18px;
}

#top-nav .contain-1120 > ul > li#library-search-button {
  background: url("../../../../images/library-search.svg") no-repeat center/18px
    18px;
  &:hover a,
  &.hover a {
    background: url("../../../../images/library-search.svg") no-repeat
      center/18px 18px;
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}

#library-search-button a {
  text-decoration: none !important;
}

.landing-page .photo-feature-4-across:after,
.landing-page .feature-four-col:after,
#library-search-dropdown:after,
#library-search:after,
.sorting-row:after,
.standard-page .three-column-links:after,
.standard-page .library-news-event:after,
#staff_directory_wrapper .details-open:after,
.info-links:after {
  content: "";
  display: table;
  clear: both;
}

#library-search-dropdown {
  .advanced-search {
    margin-top: 0.5rem;
  }
}

#library-search-dropdown,
#library-search {
  margin: 0 auto;
  background: url("../../../../images/purple-polygons.png") repeat-y;
  //background-color: rgba(22,21,20,0.7);
  padding: 1.5rem 1rem 1rem 1rem;

  .advanced-search {
    text-align: right;
    margin-right: -40px;

    a {
      font: 14px "Campton Book", Tahoma, sans-serif;
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff
      url("https://common.northwestern.edu/v8/css/images/icons/arrow-down-dkpurple.svg")
      no-repeat scroll right 1rem top 50%/20px 13px;
    border: none;
    border-radius: 0;
    color: #342f2e;
    display: inline-block;
    font: 18px/1.5em "Akkurat Pro Regular", Arial, sans-serif;
    padding: 0.5em 2.5em 0.5em 0.5em;
    height: 40px;
    width: 75%;

    &::-ms-expand {
      display: none;
    }
  }
}

#library-search-dropdown .search-column,
#library-search-dropdown .by-column,
#library-search-dropdown .for-column,
#library-search .search-column,
#library-search .by-column,
#library-search .for-column {
  float: left;
  position: relative;
}
#library-search-dropdown .search-column span,
#library-search-dropdown .by-column span,
#library-search-dropdown .for-column span,
#library-search .search-column span,
#library-search .by-column span,
#library-search .for-column span {
  text-transform: uppercase;
  font: 18px "Campton Bold", Impact, sans-serif;
  color: #fff;
  margin-right: 0.5rem;
}
#library-search-dropdown .search-column span.selected,
#library-search-dropdown .by-column span.selected,
#library-search-dropdown .for-column span.selected,
#library-search .search-column span.selected,
#library-search .by-column span.selected,
#library-search .for-column span.selected {
  margin-top: 0.5em;
  padding-left: 1rem;
  display: block;
  text-align: center;
}
#library-search-dropdown .search-column input,
#library-search-dropdown .by-column input,
#library-search-dropdown .for-column input,
#library-search .search-column input,
#library-search .by-column input,
#library-search .for-column input {
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  height: 40px;
  background: #fff;
  border: 1px solid #ccc;
  border-right: none;
  padding: 0.5em 2.5em 0.5em 0.5em;
  outline: none;
  color: #342f2e;
  font: 18px/1.5em "Akkurat Pro Regular", Arial, sans-serif;
}
/* These styles provide the purple button.  Maybe re-visit later?

#library-search-dropdown .search-column button,
#library-search-dropdown .by-column button,
#library-search-dropdown .for-column button,
#library-search .search-column button,
#library-search .by-column button,
#library-search .for-column button {
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  background: #4e2a84
    url('https://common.northwestern.edu/v8/css/images/icons/search-white.svg')
    no-repeat 10px 10px/18px 18px;
}
*/
#library-search-dropdown .search-column,
#library-search .search-column {
  width: 32%;
}
#library-search-dropdown .by-column,
#library-search .by-column {
  width: 28%;
  text-align: center;
}
#library-search-dropdown .for-column,
#library-search .for-column {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
#library-search-dropdown.home-search,
#library-search.home-search {
  padding: 4rem 1rem;
}
#library-search-dropdown.home-search .section-top h3,
#library-search.home-search .section-top h3 {
  font: 24px "Campton Extra Bold", Impact, sans-serif;
  color: #fff;
  text-align: center;
}
#library-search-dropdown.home-search .section-top h3:before,
#library-search.home-search .section-top h3:before {
  background: url("https://common.northwestern.edu/v8/css/images/icons/grey-slash.svg")
    no-repeat 50%/17px 21px;
  padding: 0 20px;
  content: " ";
}
#library-search-dropdown.home-search .section-top h3:after,
#library-search.home-search .section-top h3:after {
  background: url("https://common.northwestern.edu/v8/css/images/icons/grey-slash.svg")
    no-repeat 50%/17px 21px;
  padding: 0 25px;
  content: " ";
}
#library-search-dropdown.home-search .section-top p.subhead,
#library-search.home-search .section-top p.subhead {
  color: #fff;
  font: 46px/1.2em "Campton Book", Tahoma, sans-serif;
  margin: 0.5em 0 1em;
  text-align: center;
}
@media screen and (max-width: 1140px) {
  #main-content #library-search-dropdown .contain-1120 {
    margin: 0;
  }
  #library-search-dropdown .advanced-search,
  #library-search .advanced-search {
    margin-right: 0;
  }
  #library-search-dropdown .search-column,
  #library-search-dropdown .by-column,
  #library-search-dropdown .for-column,
  #library-search .search-column,
  #library-search .by-column,
  #library-search .for-column {
    text-align: left;
  }
  #library-search-dropdown .search-column span,
  #library-search-dropdown .by-column span,
  #library-search-dropdown .for-column span,
  #library-search .search-column span,
  #library-search .by-column span,
  #library-search .for-column span {
    display: block;
    text-align: left;
  }
  #library-search-dropdown .search-column span.selected,
  #library-search-dropdown .by-column span.selected,
  #library-search-dropdown .for-column span.selected,
  #library-search .search-column span.selected,
  #library-search .by-column span.selected,
  #library-search .for-column span.selected {
    margin-top: 1.75em;
    padding-left: 0;
    text-align: left;
  }
  #library-search-dropdown .search-column input,
  #library-search-dropdown .by-column input,
  #library-search-dropdown .for-column input,
  #library-search .search-column input,
  #library-search .by-column input,
  #library-search .for-column input {
    width: 98%;
  }
  #library-search-dropdown .search-column select,
  #library-search-dropdown .by-column select,
  #library-search-dropdown .for-column select,
  #library-search .search-column select,
  #library-search .by-column select,
  #library-search .for-column select {
    width: 90%;
  }
  #library-search-dropdown .for-column,
  #library-search .for-column {
    padding-right: 0;
  }
}
@media screen and (max-width: 768px) {
  #library-search-dropdown .advanced-search,
  #library-search .advanced-search {
    margin-right: -40px;
  }
  #library-search-dropdown .search-column,
  #library-search-dropdown .by-column,
  #library-search-dropdown .for-column,
  #library-search .search-column,
  #library-search .by-column,
  #library-search .for-column {
    float: none;
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0;
  }
  #library-search-dropdown .search-column span.selected,
  #library-search-dropdown .by-column span.selected,
  #library-search-dropdown .for-column span.selected,
  #library-search .search-column span.selected,
  #library-search .by-column span.selected,
  #library-search .for-column span.selected {
    margin-top: 1em;
    text-align: center;
    font-size: 20px;
  }
  #library-search-dropdown .search-column input,
  #library-search-dropdown .by-column input,
  #library-search-dropdown .for-column input,
  #library-search .search-column input,
  #library-search .by-column input,
  #library-search .for-column input {
    width: 98%;
  }
  #library-search-dropdown .search-column select,
  #library-search-dropdown .by-column select,
  #library-search-dropdown .for-column select,
  #library-search .search-column select,
  #library-search .by-column select,
  #library-search .for-column select {
    width: 100%;
  }
  #library-search-dropdown .search-column button,
  #library-search-dropdown .by-column button,
  #library-search-dropdown .for-column button,
  #library-search .search-column button,
  #library-search .by-column button,
  #library-search .for-column button {
    right: inherit;
  }
  #library-search-dropdown .for-column,
  #library-search .for-column {
    padding-right: 40px;
  }
}
@media screen and (max-width: 480px) {
  #library-search-dropdown.home-search {
    padding: 3rem 1rem;
  }
  #library-search-dropdown.home-search .section-top h3 {
    font: 20px "Campton Extra Bold", Impact, sans-serif;
    color: #fff;
    text-align: center;
  }
  #library-search-dropdown.home-search .section-top h3:before {
    background: none;
    padding: 0;
  }
  #library-search-dropdown.home-search .section-top h3:after {
    background: none;
    padding: 0;
  }
  #library-search-dropdown.home-search .section-top p.subhead {
    color: #fff;
    font: 28px/1.2em "Campton Book", Tahoma, sans-serif;
    margin: 0.5em 0 1em;
    text-align: center;
  }
}
