// Unstyled lists
ul.no-style {
  @include ul-no-style();

  &.horizontal {
    > li {
      display: inline-block;
    }
  }
}

// Font awesome
.fa {
  font-family: "FontAwesome" !important;
}

#page {
  // Add content margins
  &.standard-margin {
    // Taken from scss/page-types/standard-page/_main.scss:18
    margin: 2rem auto 0 auto;
    max-width: 1120px;
    @extend %clearfix;
  }
}

main#main-content {
  position: relative;
  // Extra see more link in homepage
  .see-more-link {
    margin-left: 1rem;
    small {
      font-size: 0.9rem;
    }
  }
}

.relative-wrapper {
  position: relative;
}

// TODO: Still need this?
// Background Select module
// http://www.northwestern.edu/templates/page-types/left-navigation/modules.html
.background-select-header {
  font: 42px $CamptonBook !important;
  color: $white !important;
  margin-bottom: 1rem;

  &:before,
  &:after {
    background: none !important;
    padding: 0 !important;
  }
}

// Extra swiper code
.standard-page {
  .showcase {
    &.nulib-showcase {
      // Add support for caption plus number of items darker shading
      .caption {
        padding: 0;
        .detail,
        .num-items {
          display: block;
          padding: 0.5rem 0.75rem 0.25rem;
        }
        .num-items {
          background: rgba(0, 0, 0, 0.1);
          padding: 0.25rem 0.75rem 0.5rem;
        }
      }
    }
  }
}

// Notifications
// Reference https://github.com/jesusoterogomez/react-notify-toast
div#notification-wrapper {
  font-size: 1rem;
}

// Button as a link style (for accessibility)
.button-link {
  @extend a;
  background: transparent;
  cursor: pointer;
}

.shared-link-notification {
  text-align: center;
  font-size: 1rem;
  padding: 2rem 0;
  background-color: $marigold;
}
