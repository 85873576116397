.expander > div {
  margin: 0;
}

// Styles using the React 'Collapsible' component.  See details at:
// https://github.com/glennflanagan/react-collapsible
.Collapsible__trigger {
  a {
    cursor: pointer;
  }

  &.is-open {
    a {
      @include collapsible-right-arrow();
    }
  }
}

.Collapsible__contentInner {
  margin: 1rem 2rem;
}

.collapsible-no-side-margins {
  .Collapsible__contentInner {
    margin: 1rem 0;
  }
}

.Collapsible:first-of-type {
  h3 {
    border-top: none;
  }
}
// End styles using the React 'Collapsible' component.
