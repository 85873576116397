// responsive tabs
#tabs,
.tabs {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;

  li {
    background: none;
    margin: 0 0.3rem 0 0;
    padding: 0;
    display: inline;
    vertical-align: middle;

    &:last-child {
      margin: 0;
    }

    font: 18px $AkkuratProBold;
    color: $nu-purple;
    background: $nu-purple-10;
    padding: 0.75rem 1.2rem 0.5rem 1.2rem;
    display: inline-block;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border: none; // removes default border

    &:hover {
      border: none;
      text-decoration: underline;
      background: $nu-purple;
      color: $white;
      cursor: pointer;
    }

    &.active {
      background: $nu-purple;
      color: $white;
    }

    &:focus {
      text-decoration: underline;
    }
  }
}

// multiple tab modules override
.content ul.tabs {
  padding-left: 0;
}

.content ul.tabs > li {
  background: none;
  padding: 0;
}

// left align tabs for standard-page
.standard-page #tabs,
#standard-page .tabs {
  text-align: left;
}

#tab-content,
.tab-content {
  border-top: 3px solid $nu-purple;
  margin: 0 0 3rem 0;
  padding: 1rem 0 0 0;

  > div {
    @extend %clearfix;

    h3 {
      margin-top: 1rem; // more breathing room
    }
  }

  // this nest mainly for admissions apply page
  .row {
    border-bottom: 3px solid $light-grey;
    padding: 0 0 2rem 0;
  }

  .responsive-table {
    // overrides margin-top in core.scss
    margin-top: 0;
  }

  .headings {
    display: table-cell;
    width: 360px / 1120px * 100%;
    padding-right: 1rem;

    h4 {
      font: 30px $CamptonBold;
      color: $nu-purple;

      span {
        display: block;
        color: $nu-purple-60;
      }
    }
  }

  .contents {
    display: table-cell;
    width: 740px / 1120px * 100%;

    .callout {
      background: $nu-purple-60;
      padding: 0.1rem 1rem;
      margin: 0 0 1rem 2rem;
      width: 266px;
      font: 24px $CamptonBook;
      color: $white;
      float: right;

      a {
        text-decoration: underline;
        font-family: $CamptonBook;
        color: $white;
      }

      h4 {
        font: 16px $AkkuratProBold;
        padding-top: 1rem;
        margin-bottom: 0;
        color: $white;

        & + p {
          margin: 0.5rem 0;
        }
      }
    }
  }
}

// media queries
@media screen and (max-width: 1150px) {
  #tabs li a,
  .tabs li a {
    font-size: 16px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 1000px) {
  .tab-left-side .img {
    float: none;
  }
}

@media screen and (max-width: 850px) {
  #tab-content,
  .tab-content {
    .contents .callout {
      float: none;
      margin: 1em 0 1em 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: 550px) {
  #tabs {
    li a {
      width: 100%;
      font-size: 18px;
    }
  }

  #tab-content,
  .tab-content {
    .headings,
    .contents {
      width: 100%;
      display: block;

      .callout {
        font-size: 18px;
      }

      h3 {
        font-size: 26px;
      }
    }
  }
}
