.osrv-work-title {
  font-family: $display;
  font-size: 19.2px !important;
  font-weight: 400;
}

.osrv-tilesource-select-wrapper {
  font-size: 1rem;
  line-height: 1.25rem;
}
