// news and event listing pages

.standard-page {
  // for news listing page
  .news {
    margin: 2rem 0;
    @extend %clearfix;

    img {
      float: left;
      margin: 0 1rem 1rem 0;
    }

    h4.news-title {
      font: 18px/1.3em $AkkuratProBold;
      margin-bottom: 0.5rem;
    }

    .news-date {
      font: 14px/1.4em $AkkuratProBold;
      color: $rich-black-80;
    }

    .news-description {
      margin-top: 0.5rem;
    }
  }

  // for news listing page
  .event {
    position: relative;
    min-height: 100px;
    margin-bottom: 1rem;

    .event-date {
      position: absolute;
      text-align: center;
      top: 0;
      width: 90px;
      padding: 0.25rem;
      background: $nu-purple;
      font-family: $CamptonBold;
      color: $white;
      float: left;

      .month,
      year {
        font-size: 14px;
      }

      .day {
        font-size: 36px;
      }
    }

    .event-description {
      margin-left: 105px;

      h4 {
        font: 18px/1.3em $AkkuratProBold;
        margin-bottom: 0.5rem;
        padding-bottom: 0;
      }

      > p {
        margin-top: 0;
        line-height: 1.2em;
      }
    }

    .event-time-location {
      font: 14px/1.4em $AkkuratProBold;
      color: $rich-black-80;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
  }

  // media queries
  @media screen and (max-width: 420px) {
    .news {
      img {
        float: none;
        margin: 0 0 1rem 0;
      }
    }
  }
} // end
