// "un-style" a standard NU <ul> list
@mixin ul-no-style() {
  padding-left: 0;
  li {
    background: none;
    padding-left: 0;
  }
}

@mixin mobile-full-width() {
  @media screen and (max-width: 700px) {
    width: 100%;
    float: none;
  }
}

// Collapsible arrows
@mixin collapsible-down-arrow() {
  background: url("../../../../images/arrow-right-dkpurple.svg") no-repeat
    0.5rem center/8px 16px;
  padding: 0.5em 0 0.5rem 2rem;
  border: none;
}
@mixin collapsible-right-arrow() {
  background: url("../../../../images/arrow-down-dkpurple.svg") no-repeat 0.3rem
    center/16px 8px;
}

// Custom scrollbar for cross-browser consistency
// https://css-tricks.com/the-current-state-of-styling-scrollbars/
@mixin scrollbar() {
  // --scrollbarBG: #bbb8b8;
  --scrollbarBG: #342f2e;
  --thumbBG: #716c6b;

  &::-webkit-scrollbar {
    width: 20px;
  }

  scrollbar-width: auto;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
}
