// alternating photo float (images and text)

.landing-page {
  .alternate-photo-float {
    margin-bottom: 5rem;
    @extend %clearfix;

    h4 {
      font: 30px $AkkuratProRegular;
      color: $rich-black-50;
      border-bottom: 4px solid $medium-grey;
      text-transform: none;
      padding-bottom: 0.5rem;
      margin-bottom: 1.5rem;
    }

    .image-left,
    .image-right,
    .text {
      width: 550px / 1120px * 100%;
      max-width: 550px;
      margin-right: 40px / 1120px * 100%;
      float: left;
      position: relative;
      max-height: 310px;

      &:last-child {
        margin-right: 0;
      }
    }

    .text {
      width: 530px / 1120px * 100%;
      max-width: 530px;
      margin-right: 40px / 1120px * 100%;
      max-height: none;

      &:last-child {
        margin-right: 0;
      }

      &.tl-text {
        color: #4e2a84;
        font: 24px/1.2em "Campton Book", Tahoma, sans-serif;
        margin-bottom: 1em;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 2em;
        -moz-column-gap: 2em;
        column-gap: 2em;
        margin: 0;
        padding: 0;

        li {
          background: none;
          padding: 0 0 0.5em 0;
          line-height: 1.5em;
          -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
          break-inside: avoid;
        }
      }

      .column {
        // multiple column option
        width: 45%;
        margin-right: 5%;
        float: left;

        &:last-child {
          margin-right: 0;
        }

        ul {
          -moz-column-count: 1;
          -webkit-column-count: 1;
          column-count: 1;
          -webkit-column-gap: normal;
          -moz-column-gap: normal;
          column-gap: normal;
        }
      }

      img {
        z-index: 30;
      }
    }

    .text-over-image {
      position: absolute;
      top: 20%;
      width: 100%;
      color: $white;
      text-align: center;
      z-index: 31;
      padding: 0 1em;
      font: 24px $CamptonExtraBold;

      p {
        margin: 0;
      }

      .big {
        font: 72px/1em $CamptonBold;
      }
    }
  }

  // media queries
  @media screen and (max-width: 1150px) {
    .alternate-photo-float {
      .quote {
        font-size: 20px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .alternate-photo-float {
      .quote {
        font-size: 18px;
      }

      .text-over-image {
        top: 5%; // positions text
        font-size: 24px;

        .big {
          font-size: 60px;
          line-height: 1em;
        }
      }
    }
  }

  @media screen and (max-width: 875px) {
    .alternate-photo-float {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      margin-bottom: 2rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 550px;

      h4 {
        font-size: 24px;
        margin-top: 1rem;
      }

      .image-left,
      .image-right,
      .text {
        width: 100%;
        float: none;
        position: relative;
        margin-right: 0;

        &:last-child {
          margin-right: auto;
        }
      }

      .image-right {
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1; // flexbox moves content to top
      }

      .text-over-image {
        top: 20%; // positions text
      }
    }
  }

  @media screen and (max-width: 768px) {
    .alternate-photo-float .text.tl-text {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 480px) {
    .alternate-photo-float {
      .text.tl-text {
        font-size: 18px;
      }

      .text-over-image {
        top: 1em; // positions text
        font-size: 20px;

        .big {
          font-size: 42px;
        }
      }

      .text {
        .column {
          // multiple column option
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    .alternate-photo-float {
      .text {
        ul {
          -moz-column-count: inherit;
          -moz-column-gap: inherit;
          -webkit-column-count: inherit;
          -webkit-column-gap: inherit;
          column-count: inherit;
          column-gap: inherit;
        }
      }
    }
  }

  @media screen and (max-width: 380px) {
    .alternate-photo-float {
      .text-over-image {
        font-size: 18px;
        line-height: 1em;

        .big {
          font-size: 36px;
        }
      }
    }
  }
} // end
