// footer

footer {
  background: $nu-purple;
  color: $white;
  font: 14px/1.3em $AkkuratProRegular;
  padding: 3rem 0;
  width: 100%;

  a {
    font-family: $AkkuratProRegular;
    color: $white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.footer-content {
  margin-right: 50px / 970px * 100%;
  display: inline-block;
  position: relative;
  vertical-align: top;

  ul {
    list-style-type: none;

    li {
      padding-bottom: 0.8rem;
    }
  }

  &:nth-of-type(1) {
    // NU links section
    width: 230px / 970px * 100%;

    img:not(.png) {
      // fixes NU logo spacing issue in IE
      width: 100%;
    }
  }

  &:nth-of-type(2) {
    // department address/phone/email section
    width: 250px / 970px * 100%;
  }

  &:nth-of-type(3) {
    // social media icon section
    width: 175px / 970px * 100%;
  }

  &:nth-of-type(4) {
    // department footer links section
    width: 140px / 970px * 100%;
    margin-right: 0;
  }

  p {
    margin-top: 0;
  }

  strong {
    font-family: $AkkuratProBold;
    font-weight: normal;
  }

  .footer-icon {
    height: 29px;
    width: 18px;
    left: -25px;
    margin-top: 0.5em;
  }

  img {
    margin-bottom: 1rem;
    max-width: 200px;
  }

  &.contact {
    ul {
      margin: 0 0 1rem 2rem;
      position: relative;

      &:last-of-type {
        margin-bottom: 0;
      }

      li {
        &.footer-pin-icon {
          background: url("#{$path-images}/icons/pin-drop.svg") no-repeat;
          background-size: 18px 24px;
          position: absolute;
          top: 2px;
          left: -2rem;
          height: 24px;
          width: 18px;
        }

        &.footer-phone-icon {
          background: url("#{$path-images}/icons/mobile-phone.svg") no-repeat;
          background-size: 18px 24px;
          position: absolute;
          top: 2px;
          left: -2rem;
          height: 24px;
          width: 18px;
        }

        &.footer-fax-icon {
          background: url("#{$path-images}/icons/fax.svg") no-repeat;
          background-size: 18px 24px;
          position: absolute;
          left: -2rem;
          height: 20px;
          width: 18px;
        }

        &.footer-email-icon {
          background: url("#{$path-images}/icons/email.svg") no-repeat;
          background-size: 18px 24px;
          position: absolute;
          top: 2px;
          left: -2.2rem;
          height: 24px;
          width: 18px;

          + li {
            font-size: 12px; // smaller font size for email address
          }
        }
      }
    }
  }
}

// social media icons
.social {
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  margin: 4px;
  width: 39px;
  height: 39px;
  font-size: 0;
  text-indent: -9999px;
  background: url("#{$path-images}/icons/social-media-icons.svg");
  background-color: #fff;
  transition: background 0.3s;
  border: 1px solid $white;

  &:hover {
    background-color: $nu-purple-30;
  }
}

.facebook {
  background-position: 0 0;

  &:hover {
    background-position: 0 -39px;
  }
}

.twitter {
  background-position: -39px 0;

  &:hover {
    background-position: -39px -39px;
  }
}

.instagram {
  background-position: -78px 0;

  &:hover {
    background-position: -78px -39px;
  }
}

.rss {
  background-position: -117px 0;

  &:hover {
    background-position: -117px -39px;
  }
}

.youtube {
  background-position: -156px 0;

  &:hover {
    background-position: -156px -39px;
  }
}

.tumblr {
  background-position: -195px 0;

  &:hover {
    background-position: -195px -39px;
  }
}

.wordpress {
  background-position: -234px 0;

  &:hover {
    background-position: -234px -39px;
  }
}

.futurity {
  background-position: -273px 0;

  &:hover {
    background-position: -273px -39px;
  }
}

.vimeo {
  background-position: -312px 0;

  &:hover {
    background-position: -312px -39px;
  }
}

.linkedin {
  background-position: -351px 0;

  &:hover {
    background-position: -351px -39px;
  }
}

.google-plus {
  background-position: -390px 0;

  &:hover {
    background-position: -390px -39px;
  }
}

.google-groups {
  background-position: -429px 0;

  &:hover {
    background-position: -429px -39px;
  }
}
.blog {
  background-position: -468px 0;

  &:hover {
    background-position: -468px -39px;
  }
}

.github {
  background-position: -507px 0;

  &:hover {
    background-position: -507px -39px;
  }
}

.flickr {
  background-position: -546px 0;

  &:hover {
    background-position: -546px -39px;
  }
}

.storify {
  background-position: -585px 0;

  &:hover {
    background-position: -585px -39px;
  }
}

.pinterest {
  background-position: -624px 0;

  &:hover {
    background-position: -624px -39px;
  }
}

.we-will {
  background-position: -663px 0;

  &:hover {
    background-position: -663px -39px;
  }
}

// media queries
@media screen and (max-width: 1140px) {
  footer {
    padding: 3rem 1rem; // gives left/right padding to smaller screen widths
  }
}

@media screen and (max-width: 870px) {
  .footer-content {
    // two columns
    margin-bottom: 1.5rem;

    &:nth-of-type(1),
    &:nth-of-type(3) {
      width: 45%;
      margin-right: 5%;
    }

    &:nth-of-type(2),
    &:nth-of-type(4) {
      width: 46%;
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 550px) {
  .footer-content {
    // turn into one column

    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      width: 100%;
      margin-right: 0;
    }
  }
}
