// emergency section for top of home page

#emergency,
#breaking_news {
  background: $red;
  padding: 1rem 5rem 1.5rem 5rem;
  font: 16px $AkkuratProRegular;
  color: $white;
  text-align: center;

  a {
    color: $white;
    text-decoration: underline;
    font-family: $AkkuratProBold;

    &:hover {
      text-decoration: none;
    }
  }

  h1,
  h2,
  h4,
  h4,
  h5 {
    color: $white;
  }

  h2 {
    font: 26px $CamptonBold;
  }

  h3 {
    font: 26px $CamptonBold;
  }

  h4 {
    font: 24px $CamptonBold;
  }
}

@media screen and (max-width: 768px) {
  #emergency {
    padding: 1rem 1rem 1.5rem 1rem;
    text-align: left;
  }
}
