// Individual collection page, make the Collapsible list to look a little tighter
.collection-sidebar {
  .expander1 {
    border-top: none;
    margin-top: 0;
  }
}

.standard-page .collection-items .extended {
  #sidebar {
    width: 22%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
