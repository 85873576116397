// left navigation

#left-nav {
  width: 265px / 1120px * 100%;
  float: left;
  font-family: $AkkuratProBold;
  margin-bottom: 3rem;
  outline: none !important; // removes outline from tabindex

  .mobile-back-to-top {
    display: none;
  }

  h2 {
    border-bottom: 4px solid $light-grey;
    min-height: 30px;
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
    font: 18px/1.5rem $AkkuratProBold;
    text-transform: none;

    a {
      color: $nu-purple;
      text-decoration: none;
    }
  }

  ul {
    list-style: none;

    ul {
      margin-top: 1.5rem;
      padding-left: 0.3rem;

      ul {
        margin-top: 1rem;
        padding-left: 0;
      }

      &.open {
        // new
        border-left: 4px solid $light-grey;
        margin-left: -4px;
      }
    }
  }

  li {
    padding-bottom: 1rem;
    font-size: 18px;
    line-height: 1.4em;
    font-family: $AkkuratProBold;

    a {
      color: $rich-black-50;
      text-decoration: none;
      display: block;

      &:hover {
        text-decoration: underline;
      }

      &.active {
        color: $nu-purple;
      }
    }

    // external link icon
    a[href^="http://"]::after, 
        a[href^="https://"]::after
    {
      content: url("#{$path-images}/icons/external-link-top-nav.png");
      margin-left: 0.5em;
    }

    li {
      margin-left: 0.5rem;
      padding-bottom: 1rem;
      font-size: 14px;

      li {
        margin-left: 1rem;
        font-family: $AkkuratProBoldItalic;
        color: #636262;

        a.active {
          font-family: $AkkuratProBoldItalic;
        }
      }
    }

    &:last-child {
      padding-bottom: 0.3rem;
    }

    ul.open li:last-child {
      padding-bottom: 0;
    }
  }
}

// media queries
@media screen and (max-width: 768px) {
  #left-nav {
    display: none;
  }
}
