// hero slideshow

.landing-page {
  // swiper arrows
  .swiper-button-next,
  .swiper-button-prev {
    width: 35px;
    height: 35px;
    -moz-background-size: 35px 35px;
    -webkit-background-size: 35px 35px;
    background-size: 35px 35px;
  }

  .swiper-button-prev {
    background: url("#{$path-images}/icons/arrow-right-purple-white.svg")
      no-repeat;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    left: 10px;
    right: auto;
  }

  .swiper-button-next {
    background: url("#{$path-images}/icons/arrow-right-purple-white.svg")
      no-repeat;
    right: 10px;
    left: auto;
  }

  .caption {
    font: 20px/1.3em $AkkuratProRegular;
    position: absolute;
    bottom: 0.4rem;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.3);
    color: $white;
    width: 100%;
    margin: 0;

    strong {
      font-family: $AkkuratProBold;
    }

    a {
      font-family: $AkkuratProBold;
      text-transform: uppercase;
      text-decoration: underline;
      color: $white;

      &:after {
        background: url("#{$path-images}/icons/arrow-right-white.svg") no-repeat
          50% / 8px 12px;
        padding-right: 1.5rem;
        content: " ";
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .caption {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .caption {
      position: relative;
      background: $nu-purple-60;
      font-size: 14px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 25px;
      height: 25px;
      -moz-background-size: 25px 25px;
      -webkit-background-size: 25px 25px;
      background-size: 25px 25px;
      margin-top: -3rem;
    }
  }
} // end
