// top navigation

#mobile-links {
  display: none;
}

// optional global links with available dropdown
#top-nav {
  background: $light-grey;

  .contain-1120 {
    > ul {
      width: 100%;
      line-height: 0;
      position: relative;

      a {
        color: $nu-purple;
        text-decoration: none;
        display: block;
        padding: 1rem 1.5rem;

        &:hover,
        &:focus,
        &:active {
          text-decoration: underline;
        }
      }

      // external link icon
      a[href^="http://"]::after, 
            a[href^="https://"]::after
      {
        content: url("#{$path-images}/icons/external-link-top-nav.png");
        margin-left: 0.5em;
      }

      > li {
        display: table-cell;
        text-align: center;
        line-height: 100%;
        font: 18px $AkkuratProBold;

        &:hover,
        &.hover {
          z-index: 100;

          .dropdown,
          .dropdown-one-column,
          .dropdown-two-column,
          .dropdown-quick-links {
            left: 0; // show
            z-index: 1000;
          }

          a {
            background: $light-grey2;
            animation: inherit;
            animation: none;

            &:focus {
              animation: none;
            }

            &.button {
              // overrides style above
              background: $nu-purple;
            }
          }
        }

        &.active a {
          background: $nu-purple-60;
          color: $white;
        }

        .dropdown-arrow {
          background: url("#{$path-images}/icons/arrow-down-ltpurple.svg")
            no-repeat 50%;
          width: 10px;
          height: 10px;
          display: inline-block;
          margin-left: 0.5em;
        }
      }

      // dropdown
      .dropdown,
      .dropdown-one-column,
      .dropdown-two-column,
      .dropdown-quick-links {
        position: absolute;
        background: $light-grey2;
        left: -9999em; // hide
        z-index: -9999;
        top: 100%;
        width: 100%;
        padding: 2rem;
        list-style-type: none;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex; // equal height columns

        .nav-intro,
        .nav-links {
          list-style-type: none;
          width: 50%;
          float: left;
          text-align: left;
        }

        p.intro {
          font: 30px $CamptonBold;
          color: $nu-purple;
          margin: 0 0 1rem 0;
          padding-right: 2rem;
        }

        .nav-links {
          border-left: 2px solid $medium-grey;
          padding-left: 1rem;
        }

        ul {
          display: block;
          background: inherit;

          li {
            display: block;
            text-align: left;
            width: inherit;
            line-height: 1.4em;
            margin-bottom: 1rem;

            a {
              padding: 0;
              display: inline-block;
              background: inherit;
              color: $nu-purple;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      // two column dropdown
      .dropdown-two-column {
        ul {
          width: 100% !important;
          -moz-column-count: 2;
          -webkit-column-count: 2;
          column-count: 2;
          -webkit-column-gap: 2rem;
          -moz-column-gap: 2rem;
          column-gap: 2rem;
          -webkit-column-rule: 2px solid $medium-grey;
          -moz-column-rule: 2px solid $medium-grey;
          column-rule: 2px solid $medium-grey;
          margin: 0;
          padding: 0;

          li {
            background: none;
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
            display: inline-block;
          }
        }
      }

      // two column dropdown menu with quick links
      .dropdown-quick-links {
        ul {
          display: block;
          background: inherit;

          li {
            display: block;
            text-align: left;
            width: inherit;
            line-height: 1.5em;
            padding: 0;
          }
        }

        .nav-links {
          width: 75%;
          float: left;
          text-align: left;
          border-left: none;

          ul {
            width: 100% !important; // important
            -moz-column-count: 2;
            -webkit-column-count: 2;
            column-count: 2;
            -webkit-column-gap: 2rem;
            -moz-column-gap: 2rem;
            column-gap: 2rem;
            margin: 0;
            padding: 0;

            li {
              background: none;
              -webkit-column-break-inside: avoid;
              page-break-inside: avoid;
              break-inside: avoid;
              display: inline-block; // removes extra space in 2nd column for webkit browsers

              span {
                display: block;
                line-height: 1.2em;
                font: 16px/1.2em $AkkuratProRegular;
              }
            }
          }
        }

        .nav-quick-links {
          border-left: 2px solid $medium-grey;
          margin-left: 1rem;
          padding-left: 2rem;
          float: left;
          width: 25%;
          text-align: left;

          p {
            margin-top: 0;
            font: 18px $AkkuratProBold;
            text-transform: uppercase;
          }

          ul {
            padding: 0;

            li {
              text-transform: none;
              font-size: 15px;
              line-height: 1.2em;
              @extend %purple-right-arrow;
            }
          }
        }
      }
    }
  }

  // for normal dropdown menus (do not mix and match with full-width dropdowns)
  &.narrow-dropdown {
    // appends to #top-nav

    ul li {
      position: relative;

      &:hover ul {
        &.nav-align-right {
          // use if necessary for rightmost nav item so dropdown doesn't fall outside of site boundries
          right: 0;
          left: inherit;

          li {
            text-align: right;
          }
        }
      }
    }

    .contain-1120 > ul {
      .dropdown {
        display: inherit;
        width: 320px;
        padding: 1em;
      }
    }
  }
}

// media queries
@media screen and (max-width: 768px) {
  #top-nav {
    display: none;
  }
}
